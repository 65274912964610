import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../../components";

const AnalitykaInternetowa = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Analityka internetowa",
        href: pathname,
        lang: "en"
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/oferta/analityka-internetowa",
      }}
    >
      <section className="container-fluid promotion_section">
        <div className="text-center">
          <h1>Web analytics</h1>
        </div>
        <div className="row promotion_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              The basis of properly conducted marketing activities on the Internet is a proper analysis of the statistics of a given website. Relying on data on the sources of traffic on the website, user behavior, and conversions made by them (achievement of the assumed goals) allows you to manage successful campaigns. Without this information, it is not possible to properly optimize online marketing campaigns. Knowing which products, for example, are most likely to sell from Facebook Ads campaigns, and which ones from ads in Google search engine, gives us the opportunity to optimally distribute the budget for individual channels and choose the assortment promoted in it.
            </p>
            <p className="text-center">
              <br />
              The analytical knowledge we have is not a theory - we work with analytical tools every single day. Get to know the tools we use every day:
            </p>
          </div>
        </div>
        <div className="row promotion_row promotion_img">
          <div className="col-md-12 m-auto">
            <div className="row">
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../../assets/img/offer/web_analyst_logo_ga.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Basic analytics tool (also available for e-commerce)
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../../assets/img/offer/web_analyst_logo_gds.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  A/B tests, without the need of making changes on the website
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../../assets/img/offer/web_analyst_logo_go.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Activity raports all in one place
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../../assets/img/offer/web_analyst_logo_fb.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Facebook analytics tools
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../../assets/img/offer/web_analyst_logo_hotjar.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Heatmaps, registering users activity on the website in the form of a video
                </span>
              </div>
              <div className="col-md-6 col-sm-6 col-lg-4 text-center m-auto">
                <img
                  src={require("../../../assets/img/offer/web_analyst_logo_yandex.png")}
                  className="img-fluid m-auto"
                  alt=""
                />
                <span className="text-promotion mb-1">
                  Heatmaps, registering users activity on the website in the form of a video
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row promotion_row2">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Facebook Ads and LinkedIn Ads give you the opportunity to take advantage of direct access to people within your interests - this is the key to effective recruitment and the guarantee that your ad will go straight to the selected target group! You pay only for clicks on the advertisement - i.e. for people who are interested in the advertisement and want to learn more, thanks to which you will avoid significant costs of promoting your offer on many different advertising portals.
            </p>
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Other services</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Banner ads</span>
            <Link to="/en/offer/banner-ads/">See</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/en/offer/social-media/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/en/offer/linkedin-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/en/offer/facebook-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>Google Ads</span>
            <Link to="/en/offer/google-ads/">see</Link>
          </div>
          <div className="col-md-2 box">
            <span>International Marketing Campaigns</span>
            <Link to="/en/offer/international-campaigns/">see</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default AnalitykaInternetowa;
